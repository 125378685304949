<template>
  <b-row>
    <b-colxx xxs="12" lg="12" xl="12" class="pr-6 display">
      <b-card class="mb-12 mr-12" :title="title">
        <b-form
          @submit.stop.prevent="onValidateServiceFormSubmit"
          class="av-tooltip tooltip-label-top"
        >
          <b-row>
            <b-colxx sm="4">
              <b-form-group label="Category">
                <b-form-input
                  type="text"
                  size="lg"
                  v-model="$v.service.serviceInitials.$model"
                  :state="validateServiceState('serviceInitials')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.service.serviceInitials.required"
                  >Please enter Service Initials!</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-else-if="!$v.service.serviceInitials.maxLength"
                  >Maximum Characters Allowed 10!</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>

            <b-colxx sm="4">
              <b-form-group label="Service Description">
                <b-form-input
                  type="text"
                  v-model.trim="$v.service.serviceDescription.$model"
                  :state="validateServiceState('serviceDescription')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.service.serviceDescription.maxLength"
                  >Maximum Characters Allowed 150!</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="6">
              <b-form-group label="Service Detail Description">
                <b-form-textarea
                  v-model.trim="service.serviceDetailDescription"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="3">
              <b-form-group label="Program" class="lg">
                <multiselect
                  v-model="service.program"
                  :options="groupedPrograms"
                  :multiple="false"
                  group-values="options"
                  group-label="label"
                  track-by="programID"
                  label="dropdownLabel"
                  placeholder="Select Program"
                  :close-on-select="true"
                ></multiselect>
                <div class="text-danger" v-if="!$v.service.program.required">Program is required</div>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="3">
              <b-form-group label="Display Order">
                <b-form-input
                  v-model.trim="$v.service.displayOrder.$model"
                  :state="validateServiceState('displayOrder')"
                />
                <b-form-invalid-feedback
                  v-if="!$v.service.displayOrder.required"
                  >Display Order Required!</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-else-if="!$v.service.displayOrder.numeric"
                  >Value must be a number!</b-form-invalid-feedback
                >
              </b-form-group>
            </b-colxx>
            <b-colxx sm="3">
              <b-form-group label="Service Active">
                <switches
                  v-model="service.active"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="4" lg="3" xl="2">
              <b-form-group label="Beta Service">
                <switches
                  v-model="service.betaService"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>

            <b-colxx sm="4" lg="3" xl="2">
              <b-form-group label="Web Active">
                <switches
                  v-model="service.activeOnWeb"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx sm="4" lg="3" xl="2">
              <b-form-group label="Deemed Status Available">
                <switches
                  v-model="service.deemedStatusAvailable"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
            <b-colxx sm="4" lg="3" xl="2">
              <b-form-group label="Licensure Available">
                <switches
                  v-model="service.licensureAvailable"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row align-h="left">
            <b-colxx xl="2" lg="4">
              <b-button
                block
                variant="danger"
                class="mt-4"
                size="lg"
                @click="cancelServiceForm()"
                >{{ $t('forms.cancel') }}
              </b-button>
            </b-colxx>
            <b-colxx  lg="4" xl="2">
              <b-button
                block
                type="submit"
                variant="success"
                class="mt-4"
                size="lg"
                >{{ $t('forms.submit') }}
              </b-button>
            </b-colxx>
          </b-row>
        </b-form>
      </b-card>
    </b-colxx>
  </b-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import Switches from 'vue-switches'
import ServiceMixin from '../../../../mixins/ServiceMixin.vue'
import ProgramMixin from '../../../../mixins/ProgramMixin.vue'
import DirtyDataMixin from '../../../../mixins/DirtyDataMixin.vue'
import Multiselect from 'vue-multiselect'

import { iconsmind, simplelineicons } from '../'
const {
  required,
  maxLength,
  minLength,
  numeric
} = require('vuelidate/lib/validators')

export default {
  components: {
    switches: Switches,
    multiselect: Multiselect
  },

  props: {
    serviceID: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      title: null
    }
  },
  async created () {
    if (!this.serviceID) {
      this.title = 'Add New Service'
      this.service = {
        serviceInitials: '',
        serviceDescription: '',
        programID: null,
        serviceDetailDescription: '',
        displayOrder: null
      }
    } else {
      this.title = 'Edit Service'
      await this.getServiceById(this.serviceID)
    }
    this.initDirtyDataWatcher('service')
    await this.getAllProgramsByType()
  },
  mixins: [validationMixin, ServiceMixin, ProgramMixin, DirtyDataMixin],
  validations: {
    service: {
      serviceInitials: {
        required,
        maxLength: maxLength(10)
      },
      serviceDescription: {
        maxLength: maxLength(150),
        minLength: minLength(2)
      },
      programID: {
        required
      },
      displayOrder: {
        required,
        numeric
      },
      program: {
        required
      }
    }
  },
  methods: {
    validateServiceState (name) {
      const { $dirty, $error } = this.$v.service[name]
      return $dirty ? !$error : null
    },
    onValidateServiceFormSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.saveService(this.service)
      }
    },
    async saveService (service) {
      this.service.serviceID
        ? await this.updateService(service)
        : await this.addNewService(service)
      if (this.statusCode === 200) {
        this.setDirtyData(false)
        this.$router.push({ name: 'servicesTable' })
      }
    },
    cancelServiceForm () {
      this.$router.push({ name: 'servicesTable' })
    }
  },
  computed: {}
}
</script>

<style scoped>
.form-control.is-invalid {
  border-color: #dc3545;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>
